import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { AllFilmsOnOneDate } from "../components/AllFilmsOnOneDate/AllFilmsOnOneDate.tsx";
import { AllFilmsOnAllDates } from "../components/AllFilmsOnAllDates/AllFilmsOnAllDates.tsx";
import { LoadingElement } from "../components/LoadingElement/LoadingElement.tsx";
import {
  AllScreeningsOfOneFilm,
  ScreeningsOnOneDate,
} from "../components/../types/types.ts";
import { useLocation, useSearchParams } from "react-router-dom";
import { fetchScreenings } from "../helpers/onCallFunctions.ts";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const FilmListings = () => {
  const [queryStringParameters] = useSearchParams();
  const [value, setValue] = React.useState(0);
  const [filmsByDate, setFilmsByDate] = React.useState<ScreeningsOnOneDate[]>(
    []
  );
  const [allFilmsOnAllDates, setAllFilmsOnAllDates] = React.useState<
    AllScreeningsOfOneFilm[]
  >([]);
  const [isLoading, setIsLoading] = React.useState(true);
  // eslint-disable-next-line
  const [error, setError] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const location = useLocation();

  React.useEffect(() => {
    const fetchScreeningsData = async () => {
      try {
        const venues = queryStringParameters.getAll("venues");
        const { screeningsByDate, allScreeningsOnAllDays } =
          await fetchScreenings(venues);
        setFilmsByDate(screeningsByDate);
        setAllFilmsOnAllDates(allScreeningsOnAllDays);
      } catch (error) {
        console.error("Error fetching screenings:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchScreeningsData();
  }, [queryStringParameters]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (isLoading) {
    return <LoadingElement />;
  }

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        allowScrollButtonsMobile={true}
        aria-label="scrollable force tabs example"
      >
        <Tab label="All Dates" {...a11yProps(0)} key={`all_dates_tab_label`} />
        {filmsByDate.map(({ date, films }, index) =>
          films.length > 0 ? (
            <Tab
              label={date}
              {...a11yProps(index + 1)}
              key={`${date}_tab_label`}
            />
          ) : (
            <Tab
              label={date}
              {...a11yProps(index + 1)}
              disabled
              key={`${date}_tab_label`}
            />
          )
        )}
      </Tabs>
      <CustomTabPanel value={value} index={0} key={`all_dates_films`}>
        <AllFilmsOnAllDates allFilmsOnAllDates={allFilmsOnAllDates} />
      </CustomTabPanel>
      {filmsByDate.map(({ films, date }, index) => (
        <CustomTabPanel value={value} index={index + 1} key={`${date}_films`}>
          <AllFilmsOnOneDate filmsOnOneDate={films} />
        </CustomTabPanel>
      ))}
    </Box>
  );
};
