import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {
  ScreeningsOfOneFilmOnOneDate,
} from "../../types/types";
import { FilmForOneDate } from "../Film/FilmForOneDate.tsx";

export const AllFilmsOnOneDate: React.FC<{
  filmsOnOneDate: ScreeningsOfOneFilmOnOneDate[];
}> = ({ filmsOnOneDate }) => {
  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Box sx={{ width: "95%" }}>
        <Stack spacing={3}>
          {filmsOnOneDate.map(({ title, description, screenings }, index) => (
            <FilmForOneDate
              key={index}
              title={title}
              description={description !== null ? description : ""}
              screenings={screenings}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};
