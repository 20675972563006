import React from "react";
import "./App.css";
import { Header } from "./components/Header/Header";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { VenueSelector } from "./pages/VenueSelector.tsx";
import { FilmListings } from "./pages/FilmListings.tsx";
import { ErrorPage } from "./pages/Error.tsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <VenueSelector />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/whats-on",
    element: <FilmListings />,
  },
]);

export const App = () => {
  return (
    <div id="app=page">
      <Header />
      <RouterProvider router={router} />
    </div>
  );
};
