import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";
import { FilmScreening } from "../../types/types";

const cardStyle = {
  width: 120,
  height: 160,
  display: "inline-block",
  "&:hover": {
    backgroundColor: "#f5f5f5", // Change to the desired hover background color
  },
};

export const IndividualScreeningInfo: React.FC<FilmScreening> = ({
  venue_name,
  link,
  time,
}) => {
  const handleClick = () => {
    window.open(link, "_blank");
  };

  return (
    <Link
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      sx={{ margin: 0.5 }}
    >
      <Card variant="outlined" sx={cardStyle}>
        <CardContent onClick={handleClick} sx={{ textAlign: "center" }}>
          <Typography variant="h5" component="div">
            {`${time.getHours()}:${time
              .getMinutes()
              .toString()
              .padStart(2, "0")}`}
          </Typography>
          <Typography sx={{ mb: 0.5 }} color="text.secondary" variant="body2">
            {venue_name}
          </Typography>
          <Button size="small" onClick={(e) => e.stopPropagation()}>
            <Link href={link} target="_blank" rel="noopener noreferrer">
              BOOK
            </Link>
          </Button>
        </CardContent>
      </Card>
    </Link>
  );
};
