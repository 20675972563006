import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

export const Header = () => {
  const handleButtonClick = () => {
    window.location.href = "/";
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton
            size="medium"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ textAlign: "center" }}
            onClick={handleButtonClick}
          >
            CinemaGuide
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
