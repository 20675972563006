import React from "react";
import {
  TextField,
  Autocomplete,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import "@react-google-maps/api";
import PlaceIcon from "@mui/icons-material/Place";

interface SearchBarProps {
  setLocation: (latitude: number, longitude: number) => void;
}

export const LocationSearchBar: React.FC<SearchBarProps> = ({
  setLocation,
}) => {
  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
    callbackName: "initMaps",
    requestOptions: {
      componentRestrictions: { country: "gb" },
    },
  });

  const handleInputChange = (
    event: React.SyntheticEvent,
    userInput: string
  ) => {
    setValue(userInput);
  };

  const handleSelect = async (event: React.SyntheticEvent, address: string) => {
    try {
      setValue(address, false);
      clearSuggestions();
      const results = await getGeocode({ address });
      const { lat, lng } = getLatLng(results[0]);
      setLocation(lat, lng);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  return (
    <Autocomplete
      freeSolo
      disableClearable
      options={status === "OK" ? data.map((place) => place.description) : []}
      onInputChange={handleInputChange}
      onChange={handleSelect}
      disabled={!ready}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Location"
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            type: "search",
          }}
        />
      )}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemIcon sx={{ marginRight: 1, minWidth: "auto" }}>
            <PlaceIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={option} />
        </ListItem>
      )}
    />
  );
};
