import React, { useState } from "react";
import {
  Container,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { fetchVenuesByLocation } from "../components/../helpers/onCallFunctions.ts";
import { LocationSearchBar } from "../components/LocationSearchBar/LocationSearchBar.tsx";
import { WhatsOnButton } from "../components/WhatsOnButton/WhatsOnButton.tsx";
import { StandardTextBlock } from "../components/StandardTextBlock/StandardTextBlock.tsx";
import { LoadingElement } from "../components/LoadingElement/LoadingElement.tsx";

export const VenueSelector: React.FC = () => {
  const [selectedVenues, setSelectedVenues] = useState<string[]>([]);
  const [venuesNearLocation, setVenuesNearLocation] = useState<string[]>([]);
  const [hasUserSelectedLocation, setHasUserSelectedLocation] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleLocationSelect = async (latitude: number, longitude: number) => {
    try {
      setLoading(true);
      const fetchedVenues = await fetchVenuesByLocation({
        latitude,
        longitude,
      });
      setVenuesNearLocation(fetchedVenues);
      setSelectedVenues([]);
      setHasUserSelectedLocation(true);
      setLoading(false);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleVenueCheckboxClick = (
    clickedVenue: string,
    previouslySelectedVenues: string[]
  ) => {
    if (previouslySelectedVenues.includes(clickedVenue)) {
      setSelectedVenues(
        selectedVenues.filter((venue) => venue !== clickedVenue)
      );
    } else {
      setSelectedVenues([...previouslySelectedVenues, clickedVenue]);
    }
  };

  return (
    <Container maxWidth="sm">
      {/* {!hasUserSelectedLocation && ( */}
      <StandardTextBlock
        text="Enter your location to see what's on in Cinemas near you:"
        margin={2}
        padding={2}
      />
      {/* // )} */}
      <LocationSearchBar setLocation={handleLocationSelect} />
      {loading ? (
        <LoadingElement />
      ) : (
        venuesNearLocation.length > 0 && (
          <div>
            <StandardTextBlock
              text="Tick the Venues you're interested in: "
              margin={1}
              padding={1}
            />
            <FormGroup>
              {venuesNearLocation.map((venue) => (
                <FormControlLabel
                  onChange={() =>
                    handleVenueCheckboxClick(venue, selectedVenues)
                  }
                  label={venue}
                  key={venue}
                  control={
                    <Checkbox checked={selectedVenues.includes(venue)} />
                  }
                />
              ))}
            </FormGroup>
          </div>
        )
      )}
      {hasUserSelectedLocation && venuesNearLocation.length > 0 && (
        <WhatsOnButton
          venues={selectedVenues}
          isButtonDisabled={selectedVenues.length < 1}
        />
      )}
      {hasUserSelectedLocation && venuesNearLocation.length === 0 && (
        <StandardTextBlock
          text="There are no venues to display. Try another location."
          margin={2}
          padding={2}
        />
      )}
    </Container>
  );
};
