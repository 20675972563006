import * as React from "react";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import { FilmScreening } from "../../types/types.ts";
import { Container, Grid } from "@mui/material";
import { IndividualScreeningInfo } from "../IndividualScreeningInfo/IndividualScreeningInfo.tsx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface FilmForAllDatesProps {
  title: string;
  description: string;
  screeningsByDate: { date: string; screenings: FilmScreening[] }[];
}

export const FilmForAllDates: React.FC<FilmForAllDatesProps> = ({
  title,
  screeningsByDate,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        slots={{ transition: Fade as AccordionSlots["transition"] }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={{
          "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
          "& .MuiAccordionDetails-root": {
            display: expanded ? "block" : "none",
          },
          "&:hover": {
            backgroundColor: "#f8f8f8",
          },
        }}
      >
        <AccordionSummary
          aria-controls="panel1-content"
          id="panel1-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
          {screeningsByDate.map(({ date, screenings }) => (
            <Container
              sx={{
                fontSize: "large",
                marginBottom: 2,
                marginLeft: 0,
                marginRight: 0,
              }}
              key={date}
            >
              <Typography
                sx={{ fontSize: "large", textDecoration: "underline" }}
              >
                {date}
              </Typography>
              <Grid sx={{ marginTop: 1 }} spacing={3}>
                {screenings.map(({ venue_name, link, time }) => (
                  <IndividualScreeningInfo
                    key={`${venue_name}-${time}`}
                    venue_name={venue_name}
                    link={link}
                    time={new Date(time)}
                  />
                ))}
              </Grid>
            </Container>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
