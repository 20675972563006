import { connectFunctionsEmulator, httpsCallable } from "firebase/functions";
import { functions } from "../config/firebaseConfig.ts";
import { DataFromFetchScreenings } from "../types/types.ts";

if (process.env.NODE_ENV === "development") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export const fetchScreenings = async (
  venues: string[]
): Promise<DataFromFetchScreenings> => {
  const response = httpsCallable(functions, "getScreenings");
  return response({ venues }).then((response) => {
    return response.data as DataFromFetchScreenings;
  });
};

export const fetchVenuesByLocation = async ({
  latitude,
  longitude,
}: {
  latitude: number;
  longitude: number;
}): Promise<string[]> => {
  const response = httpsCallable(functions, "getVenuesWithinRadius");
  return response({ userLatitude: latitude, userLongitude: longitude }).then(
    (response) => {
      return response.data as string[];
    }
  );
};
