import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { FilmForAllDates } from "../Film/FilmForAllDates.tsx";
import { AllScreeningsOfOneFilm } from "../../types/types.ts";

export const AllFilmsOnAllDates: React.FC<{
  allFilmsOnAllDates: AllScreeningsOfOneFilm[];
}> = ({ allFilmsOnAllDates }) => {
  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Box sx={{ width: "95%" }}>
        <Stack spacing={3}>
          {allFilmsOnAllDates.map(
            ({ title, description, screeningsByDate }) => (
              <FilmForAllDates
                key={`${title}_all_dates`}
                title={title}
                description={description ? description : ""}
                screeningsByDate={screeningsByDate}
              />
            )
          )}
        </Stack>
      </Box>
    </Box>
  );
};
