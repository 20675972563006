import * as React from "react";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import { FilmScreening } from "../../types/types.ts";
import { Container, Grid } from "@mui/material";
import { IndividualScreeningInfo } from "../IndividualScreeningInfo/IndividualScreeningInfo.tsx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface FilmForOneDateProps {
  title: string;
  description: string;
  screenings: FilmScreening[];
}

export const FilmForOneDate: React.FC<FilmForOneDateProps> = ({
  title,
  description,
  screenings,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        slots={{ transition: Fade as AccordionSlots["transition"] }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={{
          "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
          "& .MuiAccordionDetails-root": {
            display: expanded ? "block" : "none",
          },
          "&:hover": {
            backgroundColor: "#f8f8f8",
          },
        }}
      >
        <AccordionSummary
          aria-controls="panel1-content"
          id="panel1-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
          {/* <Container>
            <Container
              sx={{
                display: "flex",
                gap: 0.5,
                marginBottom: 3,
                flexWrap: "wrap",
                justifyContent: "left",
              }}
            >
              {screenings.map(({ venue_name, link, time }) => (
                <IndividualScreeningInfo
                  venue_name={venue_name}
                  link={link}
                  time={new Date(time)}
                />
              ))}
            </Container>
          </Container> */}
          <Container
            sx={{
              fontSize: "large",
              marginBottom: 2,
              marginLeft: 0,
              marginRight: 0,
            }}
          >
            <Grid sx={{ marginTop: 1 }} spacing={3}>
              {screenings.map(({ venue_name, link, time }, index) => (
                <IndividualScreeningInfo
                  key={`${venue_name}-${time}-${index}`}
                  venue_name={venue_name}
                  link={link}
                  time={new Date(time)}
                />
              ))}
            </Grid>
          </Container>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
